#root {
  height: '100%';
  width: '100%';
}

html {
  -webkit-font-smoothing: 'antialiased';
  -moz-osx-font-smoothing: 'grayscale';
  height: 100%;
  width: 100%;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}